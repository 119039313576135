/* src/pages/MemberProfile.css */
.container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .card {
    margin-bottom: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .card-title {
    font-size: 1.5rem;
    font-weight: bold;
  }
  
  .card-text {
    font-size: 1rem;
    color: #555;
  }
  
  .card img {
    border-radius: 10px 10px 0 0;
  }
  
  .feed-title {
    font-size: 2rem;
    margin-bottom: 20px;
  }
  
  .feed-item {
    padding: 20px;
    background: #fff;
    border-radius: 10px;
    margin-bottom: 20px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .feed-item-title {
    font-size: 1.25rem;
    font-weight: bold;
    color: #333;
  }
  
  .feed-item-date {
    font-size: 0.875rem;
    color: #888;
  }
  
  .feed-item-text {
    font-size: 1rem;
    color: #555;
  }
  
  .view-debate-link {
    color: #007bff;
    text-decoration: none;
    font-weight: bold;
  }
  
  .view-debate-link:hover {
    text-decoration: underline;
  }
  