/* src/App.css */
.App {
  font-family: Arial, sans-serif;
}

.bill-feed {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.bill {
  padding: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.bill:hover {
  background-color: #f0f0f0;
}

.feed {
  padding: 2rem;
}
